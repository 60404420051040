import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton, Select } from "../../../../../global";
import { Input } from "../../../../../global/input/index";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import {
    ICemeteryGrave,
    ICemeteryGraveForm,
    ICemeteryGraveResponse,
} from "../../../../../../shared/providers/cemetery/entities/grave-cemetery.interface";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";
import { graveEditValidator } from "./components/graveEditValidator";

interface EditFormProps {
    doAfterSubmit(subscription: string): void;
    graveInfo?: Partial<ICemeteryGraveResponse>;
}
export const EditGrave: React.FC<EditFormProps> = ({
    doAfterSubmit,
    graveInfo,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const id = graveInfo?.id;
    const subscription = graveInfo?.inscricao;

    const handleSubmitSepultamento = async ({
        situacaoForm,
        ocupacaoForm,
        especieForm,
        larguraForm,
        comprimentoForm,
        distritoForm,
        setorForm,
        quadraForm,
        quadraLotForm,
        loteForm,
        loteLotForm,
        areaForm,
        controleForm,
    }: ICemeteryGraveForm) => {
        const payload: ICemeteryGrave = {
            situacao: situacaoForm,
            ocupacao: ocupacaoForm,
            especie: especieForm,
            largura: larguraForm,
            distrito: distritoForm,
            comprimento: comprimentoForm,
            setor: setorForm,
            quadra: quadraForm,
            quadra_lot: quadraLotForm,
            lote: loteForm,
            lote_lot: loteLotForm,
            area: areaForm,
            controle: controleForm as string | "",
            inscricao: subscription as string | "",
        };
        try {
            setLoading(true);

            if (id) {
                await graveEditValidator(payload);

                await CemeteryInfoApiService.updateGrave(id, payload);

                showToast({
                    type: "success",
                    message: "Dados do jazigo foram atualizados com sucesso",
                });

                doAfterSubmit(subscription as string);
            }
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-50">
            <p>Editar Jazigo</p>
            {!loading && (
                <Form
                    onSubmit={handleSubmitSepultamento}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                        <Input
                            name="inscricaoForm"
                            label="INSCRIÇÃO"
                            value={subscription}
                            hidden
                            disabled
                        />

                        <Select
                            name="situacaoForm"
                            label="SITUAÇÃO"
                            defaultValue={{
                                label: graveInfo?.situacao,
                                value: graveInfo?.situacao,
                            }}
                            options={[
                                {
                                    label: "PRECÁRIA",
                                    value: "PRECÁRIA",
                                },
                                {
                                    label: "RUIM",
                                    value: "RUIM",
                                },
                                {
                                    label: "BOA",
                                    value: "BOA",
                                },
                                {
                                    label: "ÓTIMA",
                                    value: "ÓTIMA",
                                },
                            ]}
                        />
                        <Select
                            name="ocupacaoForm"
                            label="OCUPAÇÃO"
                            defaultValue={{
                                label: graveInfo?.ocupacao,
                                value: graveInfo?.ocupacao,
                            }}
                            options={[
                                {
                                    label: "CONSTRUÍDO",
                                    value: "CONSTRUÍDO",
                                },
                                {
                                    label: "VAZIO",
                                    value: "VAZIO",
                                },
                            ]}
                        />
                        <Select
                            name="especieForm"
                            label="ESPÉCIE"
                            defaultValue={{
                                label: graveInfo?.especie,
                                value: graveInfo?.especie,
                            }}
                            options={[
                                {
                                    label: "PÚBLICO",
                                    value: "PÚBLICO",
                                },
                                {
                                    label: "PARTICULAR",
                                    value: "PARTICULAR",
                                },
                            ]}
                        />
                        <Input
                            name="larguraForm"
                            label="LARGURA EM (m²)"
                            defaultValue={graveInfo?.largura}
                        />
                        <Input
                            name="comprimentoForm"
                            label="COMPRIMENTO EM (m²)"
                            defaultValue={graveInfo?.comprimento}
                        />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
