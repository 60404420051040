import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useState, useLayoutEffect } from "react";

import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";

import { BodyBcm } from "../../../../../components/page-releated/cemetery-docs/bcm/body";
import { HeaderCemeteryDocs } from "../../../../../components/page-releated/cemetery-docs/header";
import { GlobalStyle } from "../../../../../components/page-releated/cemetery-docs/header/styles";
import { useLoading } from "../../../../../shared/hooks/useLoading";
import { ILayers } from "../../../../../shared/interfaces/ILayers";
import { ICemeteryResponse } from "../../../../../shared/providers/cemetery/entities/cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../shared/services/api/cemetery-api.service";
import { LayersApiService } from "../../../../../shared/services/api/layers-api.service";

export const StandardBcm: React.FC = () => {
    const { setLoading } = useLoading();

    const { subscription } = useParams<{
        subscription: string | undefined;
    }>();

    const [bcm, setBcm] = useState<ICemeteryResponse>();
    const [layers, setLayers] = useState<ILayers[]>();

    const fetchBcm = useCallback(async () => {
        try {
            setLoading(true);

            if (!subscription) return;

            const [fetchedInfo, layersResponse] = await Promise.all([
                CemeteryInfoApiService.getCemeteryInfoByOwner(subscription, [
                    "taxes",
                    "workers",
                    "deceased",
                    "grave",
                    "transferred",
                    "attachments",
                    "geom",
                ]),
                LayersApiService.getLayers(`where={"cam_ativa_bcm":"true"}&`),
            ]);

            setBcm(fetchedInfo.data[0]);
            setLayers(layersResponse.data);
        } finally {
            setLoading(false);
        }
    }, [subscription, setLoading]);

    useLayoutEffect(() => {
        fetchBcm();
    }, [fetchBcm]);

    return (
        <>
            <GlobalStyle />
            <Container>
                <HeaderCemeteryDocs />
                {bcm && layers ? (
                    <BodyBcm cemeteryInfo={bcm} layers={layers} />
                ) : (
                    <h5>
                        O documento só ficará disponível depois de preencher o
                        cadastro do proprietário.
                    </h5>
                )}
            </Container>
        </>
    );
};
