import React, { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowLeftSquare } from "react-icons/bs";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { Input, MainButton } from "../../../../../global";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import {
    ICemeteryDeceased,
    ICemeteryDeceasedResponse,
    ICemeteryForm,
    ICemeteryWithTypeForm,
} from "../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";
import { deceasedValidator } from "./components/validator";
import { deceasedTransferredValidator } from "./components/validatorTransferred";
import { SepultamentoEditComponent } from "./components/sepultamentoEdit";
import { Cities } from "../owner/components/cities";

const labelStyle = { fontSize: "15px", color: "#234e52" };

interface EditFormProps {
    doAfterSubmit(subscription: string): void;
    backButton(): void;
    deceasedInfo?: Partial<ICemeteryDeceasedResponse>;
}
export const EditDeceased: React.FC<EditFormProps> = ({
    doAfterSubmit,
    backButton,
    deceasedInfo,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [registerType, setRegisterType] = useState<
        "SEPULTAMENTO" | "TRANSLADO RECEBIMENTO"
    >("SEPULTAMENTO");

    const id = deceasedInfo?.id;
    const subscription = deceasedInfo?.inscricao;

    const handleSubmitSepultamento = async ({
        nameForm,
        genderForm,
        birthDateForm,
        deathDateForm,
        burialDateForm,
        motherNameForm,
        fatherNameForm,
        declarationForm,
        atestadoForm,
        cartorioForm,
        funerariaForm,
        bookForm,
        paperForm,
        subscriptionForm,
        controlForm,
        exhumationDateForm,
        inscricaoFalecidoForm,
    }: ICemeteryForm) => {
        const payload: ICemeteryDeceased = {
            nome: nameForm,
            sexo: genderForm,
            data_nascimento: birthDateForm,
            data_falecimento: deathDateForm,
            data_sepultamento: burialDateForm,
            nome_mae: motherNameForm,
            nome_pai: fatherNameForm,
            declaracao: declarationForm,
            atestado: atestadoForm,
            cartorio: cartorioForm,
            funeraria: funerariaForm,
            livro: bookForm,
            folha: paperForm,
            data_exumacao: exhumationDateForm,
            inscricao: subscriptionForm,
            controle: controlForm,
            tipo_cadastramento: registerType,
            translado_cidade: "",
            translado_origem: "",
            inscricao_falecido: inscricaoFalecidoForm,
            unidade: deceasedInfo?.unidade || 0,
        };
        try {
            setLoading(true);
            if (id) {
                await deceasedValidator(payload);

                await CemeteryInfoApiService.updateDeceased(id, payload);

                showToast({
                    type: "success",
                    message: "Dados de falecido foram atualizados com sucesso",
                });

                doAfterSubmit(subscription as string);
            }
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitTransferred = async ({
        nameForm,
        genderForm,
        birthDateForm,
        deathDateForm,
        burialDateForm,
        motherNameForm,
        fatherNameForm,
        declarationForm,
        atestadoForm,
        cartorioForm,
        funerariaForm,
        bookForm,
        paperForm,
        subscriptionForm,
        controlForm,
        exhumationDateForm,
        originForm,
        cidadeForm,
        inscricaoFalecidoForm,
    }: ICemeteryWithTypeForm) => {
        const payload: ICemeteryDeceased = {
            nome: nameForm,
            sexo: genderForm,
            data_nascimento: birthDateForm,
            data_falecimento: deathDateForm,
            data_sepultamento: burialDateForm,
            nome_mae: motherNameForm,
            nome_pai: fatherNameForm,
            declaracao: declarationForm,
            atestado: atestadoForm,
            cartorio: cartorioForm,
            funeraria: funerariaForm,
            livro: bookForm,
            folha: paperForm,
            data_exumacao: exhumationDateForm,
            inscricao: subscriptionForm,
            controle: controlForm,
            tipo_cadastramento: registerType,
            translado_cidade: cidadeForm || "",
            translado_origem: originForm || "",
            inscricao_falecido: inscricaoFalecidoForm,
            unidade: deceasedInfo?.unidade || 0,
        };
        try {
            setLoading(true);
            if (id) {
                await deceasedTransferredValidator(payload);

                await CemeteryInfoApiService.updateDeceased(id, payload);

                showToast({
                    type: "success",
                    message: "Dados de falecido foram atualizados com sucesso",
                });

                doAfterSubmit(subscription as string);
            }
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    const handleRelatedByChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRegisterType(
            event.target.value as "SEPULTAMENTO" | "TRANSLADO RECEBIMENTO"
        );
    };

    useEffect(() => {
        if (deceasedInfo?.tipo_cadastramento) {
            setRegisterType(
                deceasedInfo.tipo_cadastramento as
                    | "SEPULTAMENTO"
                    | "TRANSLADO RECEBIMENTO"
            );
        }
    }, [deceasedInfo?.tipo_cadastramento]);

    const handleGoBack = () => {
        backButton();
    };

    return (
        <div className="bg-gray-50">
            <div className="grid grid-cols-3 grid-rows-1">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>VOLTAR</Tooltip>}
                >
                    <div className="mb-3 flex space-x-2 justify-center">
                        <button type="button" onClick={handleGoBack}>
                            <BsArrowLeftSquare color="teal" size={20} />
                        </button>
                    </div>
                </OverlayTrigger>
            </div>
            {!loading && (
                <div className="flex space-x-2 ml-2">
                    <p style={labelStyle}>Tipo do Cadastro:</p>
                    <FormCheck
                        type="radio"
                        name="serviceTypeSepultamento"
                        label="SEPULTAMENTO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="SEPULTAMENTO"
                        checked={registerType === "SEPULTAMENTO"}
                        disabled
                    />
                    <FormCheck
                        type="radio"
                        name="serviceTypeTranslado"
                        label="TRANSLADO RECEBIMENTO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="TRANSLADO RECEBIMENTO"
                        checked={registerType === "TRANSLADO RECEBIMENTO"}
                        disabled
                    />
                </div>
            )}
            {registerType === "SEPULTAMENTO" && deceasedInfo && (
                <Form
                    onSubmit={handleSubmitSepultamento}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <SepultamentoEditComponent deceasedInfo={deceasedInfo} />
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
            {registerType === "TRANSLADO RECEBIMENTO" && deceasedInfo && (
                <Form
                    onSubmit={handleSubmitTransferred}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <SepultamentoEditComponent deceasedInfo={deceasedInfo} />
                    <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                        <Input
                            name="originForm"
                            label="ORIGEM"
                            defaultValue={deceasedInfo.translado_origem}
                        />
                        <Cities city={deceasedInfo.translado_cidade || ""} />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
