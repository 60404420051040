import React, { useCallback } from "react";

import { BsFillPinMapFill } from "react-icons/bs";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useOlMap } from "../../../../../shared/hooks/useOlMap";
import { drawPolygon } from "../../../../../shared/utils/ol.utils";
import { showToast } from "../../../../global/toast";
import { ICemeteryDeceasedResponse } from "../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../shared/services/api/cemetery-api.service";

interface PropertyActionsProps {
    deceased: Partial<ICemeteryDeceasedResponse>;
    doAfterClick?(): void;
    iconsSize?: number;
}

export const SearchFormActionDeceased: React.FC<PropertyActionsProps> = ({
    deceased,
    doAfterClick,
    iconsSize = 18,
}) => {
    const { map } = useOlMap();

    const drawPropertyGeom = useCallback(async () => {
        if (!map) return;

        const query = `&where={"inscricao":"${deceased.inscricao}"}`;

        const propertiesResponse =
            await CemeteryInfoApiService.listCemeteryInfoPropertiesByGrave(
                query,
                ["geom"]
            );

        if (!propertiesResponse.data[0].geom) {
            showToast({ type: "warn", message: "Lote não encontrado" });

            return;
        }

        drawPolygon({
            coordinates: propertiesResponse.data[0].geom.geom,
            map,
            padding: [180, 180, 180, 180],
        });

        if (doAfterClick) {
            doAfterClick();
        }
    }, [map, deceased.inscricao, doAfterClick]);

    return (
        <OverlayTrigger placement="top" overlay={<Tooltip>LOCALIZAR</Tooltip>}>
            <button type="button" onClick={drawPropertyGeom}>
                <BsFillPinMapFill color="teal" size={iconsSize} />
            </button>
        </OverlayTrigger>
    );
};
