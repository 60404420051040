/* eslint-disable import/no-duplicates */
import React from "react";
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";

import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { ICemeteryWorkersResponse } from "../../../../shared/providers/cemetery/entities/workers-cemetery.interface";

export interface PrestadoProps {
    cemeteryInfo: ICemeteryResponse;
    idWorker: string | undefined;
}

export const PrestadorProprietarioBody: React.FC<PrestadoProps> = ({
    cemeteryInfo,
    idWorker,
}) => {
    // const formattedData = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    //     locale: ptBR,
    // });
    let currentWorker = {} as ICemeteryWorkersResponse | undefined;
    let formattedData = "";
    if (cemeteryInfo && cemeteryInfo.workers && idWorker) {
        currentWorker = cemeteryInfo.workers.find(
            (d) => d.id === parseInt(idWorker, 10)
        );
        if (currentWorker?.data_criacao) {
            formattedData = format(
                new Date(currentWorker?.data_criacao),
                "dd 'de' MMMM 'de' yyyy",
                {
                    locale: ptBR,
                }
            );
        }
    }

    return (
        <>
            <div className="w-full flex justify-center ">
                <h4 className="font-bold">AUTORIZAÇÃO DE SERVIÇO EM TÚMULO</h4>
            </div>
            <br />
            <div>
                <strong>
                    AUTORIZAMOS, {cemeteryInfo.propnome},{" "}
                    {cemeteryInfo.nacionalidade}, {cemeteryInfo.estado_civil} ,
                </strong>{" "}
                domiciliado à rua:
                <strong>
                    {" "}
                    {cemeteryInfo.logradouronome} -{" "}
                    {cemeteryInfo.logradouronumero} - {cemeteryInfo.bairronome}{" "}
                    - {cemeteryInfo.cidade} - {cemeteryInfo.estado}.
                </strong>
                <br />
                Telefone: <strong>{cemeteryInfo.telefone}</strong>
                {currentWorker && (
                    <p>
                        Para realização da{" "}
                        <strong>{currentWorker?.tipo_servico}</strong> do túmulo
                        na seguinte localização:{" "}
                        <strong>Quadra: {cemeteryInfo.geom?.quadra}</strong> no{" "}
                        <strong>Lote: {cemeteryInfo.geom?.lote}</strong>, com
                        dimensões de{" "}
                        <strong>
                            {cemeteryInfo.grave?.largura}m² X{" "}
                            {cemeteryInfo.grave?.comprimento}m²
                        </strong>
                        , no Cemitério Municipal São João Batista.
                        <br />
                        O(A) proprietário(a){" "}
                        <strong>{cemeteryInfo.propnome}</strong>, está
                        sujeito(a) a cumprir integralmente as normas
                        estabelecidas após a execução da{" "}
                        <strong>{currentWorker.tipo_servico}</strong>
                        {currentWorker.tipo_servico === "LIMPEZA" ? (
                            <>. Sendo </>
                        ) : (
                            <>
                                . O calçamento entre os túmulos é obrigatório.
                                Na ausência deste, a medida permitida é de 30cm
                                de cada lado. Além disso, é{" "}
                            </>
                        )}
                        obrigatória a retirada de todos os resíduos gerados
                        durante a realização da{" "}
                        <strong>{currentWorker.tipo_servico}</strong> e,
                        porventura, que venham a permanecer no local, devem ser
                        descartados em área indicada pela{" "}
                        <strong>ADMINISTRAÇÃO</strong> para acomodação.
                        <br />
                        <strong>
                            Qualquer obstrução de túmulo resultará em
                            advertência e notificação à empresa.{" "}
                        </strong>
                        <br />O prazo estabelecido para a conclusão dos
                        trabalhos é de <strong>20 dias</strong> a partir da data
                        de assinatura desta autorização, não podendo ser
                        prorrogado além do prazo determinado pela Administração,
                        que será responsável pela fiscalização do serviço. A não
                        conformidade com as normas acarretará na impossibilidade
                        de autorização para serviços futuros.
                    </p>
                )}
            </div>
            <div className="w-full flex justify-end">
                <h5 className="font-bold">Jacarezinho, {formattedData}.</h5>
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>Manoel Aparecido Ferreira</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>Chefe de Divisão A.R.C e Arquivo Geral</strong>
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>{cemeteryInfo.propnome}</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>PROPRIETÁRIO(A)</strong>
            </div>
        </>
    );
};
