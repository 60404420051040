import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { BsArrowLeftSquare, BsPlusSquare } from "react-icons/bs";
import { ICemeteryUtilsList } from "../../../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../../../shared/utils/errors.utils";
import { AddFuneral } from "./add-funeral";

export const RegisterFuneral: React.FC = () => {
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [list, setList] = useState<ICemeteryUtilsList[]>([]);

    const fetchData = useCallback(async () => {
        try {
            const controlListResponse =
                await CemeteryInfoApiService.getFuneralList();

            setList(controlListResponse);
        } catch (error) {
            ApiErrorHandler(error, "warn");
        }
    }, []);

    const handleEnableCreate = () => {
        setEnableCreate(!enableCreate);
    };

    const handleSubmitCreate = () => {
        setEnableCreate(!enableCreate);
        fetchData();
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div className="grid grid-cols-3 grid-rows-1">
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {enableCreate ? "VOLTAR" : "ADICIONAR FALECIDO"}
                        </Tooltip>
                    }
                >
                    <div className="mb-3 flex space-x-2 justify-center">
                        <button type="button" onClick={handleEnableCreate}>
                            {enableCreate ? (
                                <BsArrowLeftSquare color="teal" size={20} />
                            ) : (
                                <BsPlusSquare color="teal" size={20} />
                            )}
                        </button>
                    </div>
                </OverlayTrigger>
            </div>

            {enableCreate && <AddFuneral doAfterSubmit={handleSubmitCreate} />}
            {!enableCreate && (
                <div className="grid grid-cols-6 gap-2">
                    {list.map((value: ICemeteryUtilsList) => (
                        <div className="col-span-2">
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            FUNERÁRIA
                                        </Form.Label>
                                        <Form.Control
                                            value={value?.nome}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
