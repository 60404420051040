import React, { useCallback } from "react";

import { BsFillPinMapFill } from "react-icons/bs";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useOlMap } from "../../../../../shared/hooks/useOlMap";
import { ICemeteryResponse } from "../../../../../shared/providers/cemetery/entities/cemetery.interface";
import { drawPolygon } from "../../../../../shared/utils/ol.utils";
import { showToast } from "../../../../global/toast";

interface PropertyActionsProps {
    property: Partial<ICemeteryResponse>;
    doAfterClick?(): void;
    iconsSize?: number;
}

export const SearchFormAction: React.FC<PropertyActionsProps> = ({
    property,
    doAfterClick,
    iconsSize = 18,
}) => {
    const { map } = useOlMap();

    const drawPropertyGeom = useCallback(() => {
        if (!map) return;

        if (!property.geom) {
            showToast({ type: "warn", message: "Lote não encontrado" });

            return;
        }

        drawPolygon({
            coordinates: property.geom.geom,
            map,
            padding: [180, 180, 180, 180],
        });

        if (doAfterClick) {
            doAfterClick();
        }
    }, [map, doAfterClick, property.geom]);

    return (
        <OverlayTrigger placement="top" overlay={<Tooltip>LOCALIZAR</Tooltip>}>
            <button type="button" onClick={drawPropertyGeom}>
                <BsFillPinMapFill color="teal" size={iconsSize} />
            </button>
        </OverlayTrigger>
    );
};
