/* eslint-disable import/no-duplicates */
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import React from "react";

import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { ICemeteryDeceasedResponse } from "../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";

export interface TransladoExternoProps {
    cemeteryInfo: ICemeteryResponse;
    deceasedSubscription: string | undefined;
}

export const TransladoExternoBody: React.FC<TransladoExternoProps> = ({
    cemeteryInfo,
    deceasedSubscription,
}) => {
    const formattedData = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
    });

    let currentDeceased = {} as ICemeteryDeceasedResponse | undefined;

    if (cemeteryInfo && cemeteryInfo.deceased && cemeteryInfo.transferred) {
        currentDeceased = cemeteryInfo.deceased.find(
            (d) => d.inscricao_falecido === deceasedSubscription
        );
    }

    return (
        <div>
            {currentDeceased?.tipo_cadastramento === "TRANSLADO RECEBIMENTO" ? (
                <div>
                    <div className="w-full flex justify-center">
                        <h4 className="font-bold">DECLARAÇÃO DE RECEBIMENTO</h4>
                    </div>
                    <br />
                    <div>
                        Declaro para os devidos fins de translado a existência
                        do Jazigo de{" "}
                        <strong>{cemeteryInfo.propnome.toUpperCase()}</strong>,
                        localizado na Quadra:{" "}
                        <strong>{cemeteryInfo.grave?.quadra}</strong>, Lote:{" "}
                        <strong>{cemeteryInfo.grave?.lote}</strong> no Cemitério
                        Municipal São João Batista, Jacarezinho – PR. Informamos
                        que o mesmo está apto a receber os restos mortais de:
                    </div>
                    <br />
                    <div>
                        <strong>{currentDeceased?.nome}</strong>, falecido no
                        dia <strong>{currentDeceased?.data_falecimento}</strong>{" "}
                        e sepultado em{" "}
                        <strong>{currentDeceased?.translado_origem}</strong>, no
                        município de{" "}
                        <strong>{currentDeceased?.translado_cidade}</strong>.
                    </div>
                    <br />
                    <div>
                        Responsável pelo translado:{" "}
                        <strong>{cemeteryInfo.propnome.toUpperCase()}</strong>,
                        domiciliado(a) à&nbsp;
                        <strong>
                            {cemeteryInfo.logradouronome.toUpperCase()}
                        </strong>{" "}
                        nº{" "}
                        <strong>
                            {cemeteryInfo.logradouronumero.toUpperCase()}
                        </strong>{" "}
                        -{" "}
                        <strong>{cemeteryInfo.bairronome.toUpperCase()}</strong>
                        , no município de&nbsp;
                        <strong>{cemeteryInfo.cidade}</strong>&nbsp;-&nbsp;
                        <strong>{cemeteryInfo.estado}</strong>.
                    </div>
                    <br />
                    <div>
                        <strong>OBSERVAÇÃO:</strong> A liberação para o
                        recebimento só ocorrerá mediante a apresentação do termo
                        liberatório do Cemitério/Crematório de origem, bem como
                        o comprovante de pagamento da taxa de sepultamento.
                    </div>
                    <br />
                    <div>Esta declaração é válida por 30 (trinta) dias.</div>
                    <br />

                    <div className="w-full flex justify-end">
                        <h5 className="font-bold">
                            Jacarezinho, {formattedData}.
                        </h5>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="w-full flex justify-center">
                        <h5 className="font-bold">
                            ________________________________________________________________________
                        </h5>
                    </div>
                    <div className="w-full flex justify-center">
                        <strong>Manoel Aparecido Ferreira</strong>
                    </div>
                    <div className="w-full flex justify-center">
                        <strong>Chefe de Divisão A.R.C e Arquivo Geral</strong>
                    </div>
                </div>
            ) : (
                <div>
                    ESTE FALECIDO NÃO POSSUI ESSE DOCUMENTO, POR FAVOR
                    VERIFIQUE.
                </div>
            )}
        </div>
    );
};
