import React from "react";
import environments from "../../../../environments";

import { StandardBcm } from "./providers/standard";

export const Bcm: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <StandardBcm />;
    }
};
