/* eslint-disable import/no-duplicates */
import React from "react";
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { ICemeteryDeceasedResponse } from "../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";

export interface Props {
    cemeteryInfo: ICemeteryResponse;
    idDeceased: string | undefined;
}

export const BodyAutorizacaoAberturaTumulo: React.FC<Props> = ({
    cemeteryInfo,
    idDeceased,
}) => {
    const formattedData = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
    });

    let currentDeceased = {} as ICemeteryDeceasedResponse | undefined;

    if (cemeteryInfo && cemeteryInfo.deceased && idDeceased) {
        currentDeceased = cemeteryInfo.deceased.find(
            (d) => d.id === parseInt(idDeceased, 10)
        );
    }

    return (
        <>
            <div className="w-full flex justify-center ">
                <h4 className="font-bold">AUTORIZAÇÃO DE ABERTURA DE TÚMULO</h4>
            </div>
            <br />
            <div>
                <p>
                    <strong>{cemeteryInfo.propnome}</strong>
                    &nbsp;, <strong>{cemeteryInfo.nacionalidade}</strong>,{" "}
                    <strong>{cemeteryInfo.estado_civil}</strong>, inscrito(a) no
                    CPF sob o nº &nbsp;
                    <strong>{cemeteryInfo.propdocumento}</strong>, portador(a)
                    do RG:&nbsp;
                    <strong>{cemeteryInfo.proprg}</strong>, e domiciliado(a)
                    à&nbsp;
                    <strong>{cemeteryInfo.logradouronome}</strong>,{" "}
                    <strong>{cemeteryInfo.logradouronumero}</strong> -{" "}
                    <strong>{cemeteryInfo.bairronome}</strong>, no município
                    de&nbsp;
                    <strong>{cemeteryInfo.cidade}</strong>&nbsp;-&nbsp;
                    <strong>{cemeteryInfo.estado}</strong>.
                </p>
                <p>
                    Telefone: <strong>{cemeteryInfo.telefone}</strong>
                </p>
                <p>
                    <strong>AUTORIZO ABERTURA DO TÚMULO</strong>, do(a):
                </p>
                <p>
                    {currentDeceased && (
                        <>
                            Falecido(a): <strong>{currentDeceased.nome}</strong>{" "}
                            Sexo: <strong>{currentDeceased.sexo || ""}</strong>
                            <br />
                            Quadra n°:{" "}
                            <strong>{cemeteryInfo.geom?.quadra}</strong> Lote
                            n°: <strong>{cemeteryInfo.geom?.lote}</strong>
                            <br />
                            Data de Nascimento:{" "}
                            <strong>{currentDeceased?.data_nascimento}</strong>
                            <br />
                            Data de Falecimento:{" "}
                            <strong>
                                {currentDeceased?.data_falecimento}
                            </strong>{" "}
                            <br />
                            Data de Sepultamento:{" "}
                            <strong>
                                {currentDeceased?.data_sepultamento}
                            </strong>
                            <br />
                            Funerária:{" "}
                            <strong>{currentDeceased.funeraria}</strong>
                            <br />
                            Declaração de Óbito nº:{" "}
                            <strong>{currentDeceased.declaracao}</strong>
                            <br />
                            Certidão de Óbito nº:{" "}
                            <strong>{currentDeceased.atestado}</strong>
                        </>
                    )}
                </p>
                Responsabilizo-me e declaro-me que será realizado no local.
            </div>
            <br />
            Por ser expressão da verdade dato e assino a presente
            <strong> AUTORIZAÇÃO.</strong>
            <br />
            <br />
            <div className="w-full flex justify-end">
                <h5 className="font-bold">Jacarezinho, {formattedData}.</h5>
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <p className="font-bold">
                    Divisão de Administração do Cemitério Municipal São João
                    Batista
                </p>
            </div>
            <br />
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>{cemeteryInfo.propnome}</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>PROPRIETÁRIO(A)</strong>
            </div>
        </>
    );
};
