import React from "react";

import { ClientLogo } from "../../../../shared/assets/logos";

import { Container } from "./styles";

export const HeaderCemeteryDocs: React.FC = () => {
    return (
        <Container>
            <div>
                <div
                    className="absolute"
                    style={{ width: "150px", marginTop: "35px" }}
                >
                    <img src={ClientLogo} alt="logo cidade" />
                </div>
                <div className="grid grid-cols-6 gap-1 pt-2 ">
                    <div className="col-span-6 text-center leading-tight">
                        <h3 className="leading-tight">
                            PREFEITURA MUNICIPAL DE JACAREZINHO
                        </h3>
                        <h5 className="leading-tight">
                            SECRETARIA MUNICIPAL DE ADMINISTRAÇÃO
                        </h5>
                        <h5 className="leading-tight">
                            CEMITÉRIO MUNICIPAL DE JACAREZINHO
                        </h5>
                        Rua: Cel. Batista, 335 – Centro - Cep: 86400-000
                        <br />
                        Fone: (43) 3911-3104
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                            CNPJ: 76.966.860/0001-46
                        </span>
                        <br />
                        Site:&nbsp;
                        <a
                            href="www.jacarezinho.pr.gov.br"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.jacarezinho.pr.gov.br
                        </a>
                        <p>
                            E-mail:&nbsp;
                            <a
                                href="mailto:administracao@jacarezinho.pr.gov.br"
                                target="_blank"
                                rel="noreferrer"
                            >
                                administracao@jacarezinho.pr.gov.br
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
};
