import React, { useCallback, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    BsArrowLeftSquare,
    BsPlusSquare,
    BsPencilSquare,
    BsPrinter,
    BsTrash,
} from "react-icons/bs";
import { MdOutlineExpandMore } from "react-icons/md";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { ICemeteryWorkersResponse } from "../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { AddWorkers } from "./add";
import { EditWorkers } from "./edit";
import { ReadWorkers } from "./read";
import env from "../../../../../../environments";
import { Popup } from "../../../../../global/popup";

interface Props {
    doAfterSubmit(subscription: string): void;
    info?: Partial<ICemeteryWorkersResponse[]>;
    infoLayer?: Record<string, string>;
}

export const Workers: React.FC<Props> = ({
    doAfterSubmit,
    info,
    infoLayer,
}) => {
    const { user } = useAuth();
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [beingEditted, setBeingEdited] = useState<number>(0);
    const [currentWorker, setCurrentWorker] =
        useState<ICemeteryWorkersResponse>();
    const [popup, setPopup] = useState<{
        show: boolean;
        id: number | undefined;
    }>();

    const handleEnableEdit = (id: number) => {
        setBeingEdited(id);
        setEnableEdit(!enableEdit);
        setLoading(!loading);
    };
    const handleEnableCreate = () => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
    };
    const handleAfterCreateSubmit = (subscription: string) => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };
    const handleAfterEditSubmit = (subscription: string) => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleValidate = (data_cadastramento: string) => {
        const start = new Date(data_cadastramento);
        const now = new Date();

        const diffInDays = Math.floor(
            (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
                Date.UTC(
                    start.getFullYear(),
                    start.getMonth(),
                    start.getDate()
                )) /
                (1000 * 60 * 60 * 24)
        );

        if (diffInDays < 20) {
            return "VÁLIDO";
        }
        return "EXPIRADO";
    };

    const handleDelete = async (id: number | undefined) => {
        if (id) {
            setPopup({
                show: true,
                id,
            });
        }
    };

    const handleDeleteCancel = () => {
        setPopup({
            show: false,
            id: undefined,
        });
    };

    const handleDeleteConfirm = useCallback(async () => {
        if (popup && popup.show && popup.id) {
            try {
                if (popup.id) {
                    setLoading(true);

                    await CemeteryInfoApiService.deleteWorker(popup.id);

                    showToast({
                        message: `As informações do prestador foram deletadas com sucesso`,
                        type: "success",
                    });

                    doAfterSubmit(infoLayer?.inscricao as string);
                }
            } catch (err) {
                ApiErrorHandler(err, "warn");
            } finally {
                setLoading(false);
            }
        }
        setPopup({
            show: false,
            id: undefined,
        });
    }, [doAfterSubmit, infoLayer?.inscricao, popup]);

    return (
        <>
            <div className="grid grid-cols-3 grid-rows-1">
                {!enableEdit && !currentWorker && (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {enableCreate
                                    ? "VOLTAR"
                                    : "ADICIONAR PRESTADOR"}
                            </Tooltip>
                        }
                    >
                        <div className="mb-3 flex space-x-2 justify-center">
                            {user.authorizationsByFeatureName[
                                FeaturesEnum.CEMETERY_WORKERS
                            ]?.canCreate && (
                                <button
                                    type="button"
                                    onClick={handleEnableCreate}
                                >
                                    {enableCreate ? (
                                        <BsArrowLeftSquare
                                            color="teal"
                                            size={20}
                                        />
                                    ) : (
                                        <BsPlusSquare color="teal" size={20} />
                                    )}
                                </button>
                            )}
                        </div>
                    </OverlayTrigger>
                )}
            </div>
            {popup && popup.show && (
                <Popup
                    phrase="Tem certeza que deseja apagar o prestador?"
                    show
                    handleConfirm={handleDeleteConfirm}
                    handleCancel={handleDeleteCancel}
                />
            )}
            {enableCreate && (
                <AddWorkers
                    subscription={infoLayer?.inscricao}
                    doAfterSubmit={handleAfterCreateSubmit}
                />
            )}
            {enableEdit && info && (
                <EditWorkers
                    workersInfo={info[beingEditted]}
                    doAfterSubmit={handleAfterEditSubmit}
                    backButton={() => setEnableEdit(false)}
                />
            )}
            {currentWorker && (
                <ReadWorkers
                    info={currentWorker}
                    backButton={() => setCurrentWorker(undefined)}
                />
            )}
            {!enableCreate &&
                !enableEdit &&
                !currentWorker &&
                info?.map((worker, index) => (
                    <Form.Group
                        key={worker?.id}
                        style={{
                            margin: "1rem",
                            padding: "1rem",
                            border: "2px solid teal",
                            borderRadius: "1rem",
                        }}
                    >
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TIPO DO PRESTADOR
                                </Form.Label>
                                <Form.Control
                                    value={worker?.tipo_prestador}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TIPO DO SERVIÇO
                                </Form.Label>
                                <Form.Control
                                    value={worker?.tipo_servico}
                                    disabled
                                />
                            </Col>
                            <Col>
                                {worker?.data_cadastramento && (
                                    <Col>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            VALIDADE
                                        </Form.Label>
                                        <Form.Control
                                            value={handleValidate(
                                                worker.data_cadastramento
                                            )}
                                            disabled
                                        />
                                    </Col>
                                )}
                            </Col>
                            <Col>
                                {worker?.data_cadastramento &&
                                handleValidate(worker.data_cadastramento) ===
                                    "VÁLIDO" ? (
                                    <>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            AÇÕES
                                        </Form.Label>
                                        <div className="mb-3 flex space-x-2">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>EXPANDIR</Tooltip>
                                                }
                                            >
                                                <div className="mb-3 flex space-x-2 justify-center">
                                                    {user
                                                        .authorizationsByFeatureName[
                                                        FeaturesEnum
                                                            .CEMETERY_WORKERS
                                                    ]?.canRead && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                setCurrentWorker(
                                                                    worker
                                                                )
                                                            }
                                                        >
                                                            <MdOutlineExpandMore
                                                                color="teal"
                                                                size={20}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        EDITAR PRESTADOR
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="mb-3 flex space-x-2 justify-center">
                                                    {user
                                                        .authorizationsByFeatureName[
                                                        FeaturesEnum
                                                            .CEMETERY_WORKERS
                                                    ]?.canUpdate && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                handleEnableEdit(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <BsPencilSquare
                                                                color="teal"
                                                                size={20}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </OverlayTrigger>

                                            {env.webgeo.config.bci &&
                                                worker &&
                                                worker.tipo_prestador ===
                                                    "EMPRESA" && (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                AUTORIZAÇÃO DE
                                                                REFORMA
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="mb-3 flex space-x-2 justify-center">
                                                            {user
                                                                .authorizationsByFeatureName[
                                                                FeaturesEnum
                                                                    .AUTORIZACAO_REFORMA
                                                            ]?.canRead && (
                                                                <a
                                                                    href={`/autorizacao-empresa/${worker.inscricao}/${worker.id}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <BsPrinter
                                                                        color="teal"
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                    </OverlayTrigger>
                                                )}
                                            {env.webgeo.config.bci &&
                                                worker &&
                                                worker.tipo_prestador ===
                                                    "PROPRIETÁRIO" && (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                AUTORIZAÇÃO DE
                                                                REFORMA
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="mb-3 flex space-x-2 justify-center">
                                                            {user
                                                                .authorizationsByFeatureName[
                                                                FeaturesEnum
                                                                    .AUTORIZACAO_REFORMA
                                                            ]?.canRead && (
                                                                <a
                                                                    href={`/autorizacao-proprietario/${worker.inscricao}/${worker.id}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <BsPrinter
                                                                        color="teal"
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                    </OverlayTrigger>
                                                )}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>APAGAR</Tooltip>
                                                }
                                            >
                                                <div className="mb-3 flex space-x-2 justify-center">
                                                    {user
                                                        .authorizationsByFeatureName[
                                                        FeaturesEnum
                                                            .CEMETERY_WORKERS
                                                    ]?.canRemove && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    worker?.id
                                                                )
                                                            }
                                                        >
                                                            <BsTrash
                                                                color="red"
                                                                size={20}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            AÇÕES
                                        </Form.Label>
                                        <div className="mb-3 flex space-x-2">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setCurrentWorker(worker)
                                                }
                                            >
                                                <MdOutlineExpandMore
                                                    color="teal"
                                                    size={20}
                                                />
                                            </button>

                                            <button
                                                type="button"
                                                // onClick={handleEnableCreate}
                                            >
                                                <BsPrinter
                                                    color="teal"
                                                    size={20}
                                                />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    NOME
                                </Form.Label>
                                <Form.Control
                                    value={worker?.nome_colaborador}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    FUNÇÃO
                                </Form.Label>
                                <Form.Control
                                    value={worker?.funcao_colaborador}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                ))}
        </>
    );
};
