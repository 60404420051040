import React from "react";
import {
    Col,
    Form,
    FormGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { BsArrowLeftSquare } from "react-icons/bs";
import { ICemeteryTransferredResponse } from "../../../../../../shared/providers/cemetery/entities/transferred.interface";

interface Props {
    backButton(): void;
    info?: Partial<ICemeteryTransferredResponse>;
}

export const ReadTransferred: React.FC<Props> = ({ backButton, info }) => {
    const handleGoBack = () => {
        backButton();
    };
    return (
        <div className="bg-gray-50">
            <div className="grid grid-cols-3 grid-rows-1">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>VOLTAR</Tooltip>}
                >
                    <div className="mb-3 flex space-x-2 justify-center">
                        <button type="button" onClick={handleGoBack}>
                            <BsArrowLeftSquare color="teal" size={20} />
                        </button>
                    </div>
                </OverlayTrigger>
            </div>
            <div className="flex justify-center items-center w-full">
                <Form.Label style={{ fontWeight: "bold" }}>
                    INFORMAÇÃO TRANSLADO
                </Form.Label>
            </div>

            {info?.inscricao && info?.tipo_translado === "TRANSLADO INTERNO" && (
                <FormGroup key={info?.id}>
                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                FALECIDO
                            </Form.Label>
                            <Form.Control value={info?.nome} disabled />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                TIPO DE TRANSLADO
                            </Form.Label>
                            <Form.Control
                                value={info?.tipo_translado}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                INSCRIÇÃO
                            </Form.Label>
                            <Form.Control
                                value={info?.inscricao_falecido}
                                disabled
                            />
                        </Col>
                    </Row>
                    <br />
                    <div className="flex justify-center items-center w-full">
                        <Form.Label style={{ fontWeight: "bold" }}>
                            INFORMAÇÃO DO JAZIGO DESTINO
                        </Form.Label>
                    </div>

                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                CONTROLE
                            </Form.Label>
                            <Form.Control value={info?.controle} disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                QUADRA
                            </Form.Label>
                            <Form.Control value={info?.quadra} disabled />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                LOTE
                            </Form.Label>
                            <Form.Control value={info?.lote} disabled />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                JAZIGO
                            </Form.Label>
                            <Form.Control value={info?.jazigo} disabled />
                        </Col>
                    </Row>
                </FormGroup>
            )}
            {info?.tipo_translado &&
                info?.tipo_translado === "TRANSLADO ENVIO" && (
                    <FormGroup key={info?.id}>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    FALECIDO
                                </Form.Label>
                                <Form.Control value={info?.nome} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TIPO DE TRANSLADO
                                </Form.Label>
                                <Form.Control
                                    value={info?.tipo_translado}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    INSCRIÇÃO
                                </Form.Label>
                                <Form.Control
                                    value={info?.inscricao_falecido}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <div className="flex justify-center items-center w-full">
                            <Form.Label style={{ fontWeight: "bold" }}>
                                INFORMAÇÃO DO CEMITÉRIO DESTINO
                            </Form.Label>
                        </div>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    CEMITÉRIO DESTINO
                                </Form.Label>
                                <Form.Control
                                    value={info?.cemiterio_destino}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    QUADRA
                                </Form.Label>
                                <Form.Control value={info?.quadra} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    LOTE
                                </Form.Label>
                                <Form.Control value={info?.lote} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    JAZIGO
                                </Form.Label>
                                <Form.Control value={info?.jazigo} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    CIDADE
                                </Form.Label>
                                <Form.Control value={info?.cidade} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    ESTADO
                                </Form.Label>
                                <Form.Control value={info?.estado} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    PAÍS
                                </Form.Label>
                                <Form.Control value={info?.pais} disabled />
                            </Col>
                        </Row>
                    </FormGroup>
                )}
        </div>
    );
};
