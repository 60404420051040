/* eslint-disable import/no-duplicates */
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import React from "react";

import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";

export interface ModeloDePosseProps {
    cemeteryInfo: ICemeteryResponse;
}

export const BodyModeloDePosse: React.FC<ModeloDePosseProps> = ({
    cemeteryInfo,
}) => {
    const formattedData = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
    });
    return (
        <>
            <div className="w-full flex justify-center">
                <h4 className="font-bold">
                    TERMO DE POSSE E USO RESPONSABILIDADE DE TÚMULO
                </h4>
            </div>
            <br />
            <div>
                <p>
                    <strong>{cemeteryInfo.propnome}</strong>&nbsp;, inscrito(a)
                    no CPF sob o nº &nbsp;
                    <strong>{cemeteryInfo.propdocumento}</strong>, e portador(a)
                    do RG:&nbsp;
                    <strong>{cemeteryInfo.proprg}</strong>, domiciliado(a)
                    à&nbsp;
                    <strong>{cemeteryInfo.logradouronome}</strong> -{" "}
                    <strong>{cemeteryInfo.logradouronumero}</strong> -{" "}
                    <strong>{cemeteryInfo.bairronome}</strong>, no município
                    de&nbsp;
                    <strong>{cemeteryInfo.cidade}</strong>&nbsp;-&nbsp;
                    <strong>{cemeteryInfo.estado}</strong>.
                </p>
                <p>
                    Cessionário(a) do <strong>JAZIGO</strong> localizado
                    na&nbsp;
                    <strong>QUADRA:</strong>&nbsp;
                    <strong>{cemeteryInfo.geom?.quadra}</strong>,{" "}
                    <strong>
                        LOTE:&nbsp;
                        {cemeteryInfo.geom?.lote}&nbsp;
                    </strong>
                    com dimensões de&nbsp;
                    <strong>
                        {cemeteryInfo.grave?.largura}m² X&nbsp;
                        {cemeteryInfo.grave?.comprimento}m²
                    </strong>
                    , no Cemitério Municipal São João Batista, deste município.
                </p>
                <p>
                    O(a) detentor(a) desta posse deverá realizar as seguintes
                    benfeitorias em seu jazigo: reboco, revestimentos (pisos) e
                    pinturas para demarcação, no&nbsp;
                    <strong>prazo de 120 dias</strong>, contados a partir da
                    presente data. O não cumprimento deste prazo implicará na
                    renúncia a esta cessão ao município.
                </p>
                <p>
                    A pessoa responsável estará ciente de que o município poderá
                    emitir a taxa anual de manutenção e conservação, ficando a
                    identificação sob sua responsabilidade, conforme o Termo de
                    Posse.
                </p>
                <p>
                    Considerando que este <strong>TERMO</strong> não autoriza a
                    sucessão sem a prévia autorização da Prefeitura Municipal de
                    Jacarezinho.
                </p>
            </div>
            <div className="w-full flex justify-end">
                <h5 className="font-bold">Jacarezinho, {formattedData}.</h5>
            </div>
            <br />
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>{cemeteryInfo.propnome}</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>
                    CPF:&nbsp;{cemeteryInfo.propdocumento}
                    &nbsp;RG:&nbsp;{cemeteryInfo.proprg}
                </strong>
            </div>
            <br />
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>Manoel Aparecido Ferreira</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>Chefe de Divisão A.R.C e Arquivo Geral</strong>
            </div>
        </>
    );
};
